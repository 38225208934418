<template lang="pug">
  div
    v-row
      v-col(cols="12" v-if="!editMode")
        v-autocomplete(
          label="Incubator"
          hide-details
          :items="incubatorList"
          v-model="incubatorCode"
          clearable
        )
      v-col(cols="12")
        v-autocomplete(
          v-if="!editMode"
          label="Incubator Rack"
          :items="filteredIncubatorRackIdsList"
          :loading="incubatorRackIdsGetting"
          item-value="id"
          item-text="id"
          dense
          no-filter
          :search-input.sync="searchIncubatorRackIds"
          v-model="rackEggId"
          hide-details="auto"
          :error-messages="candledRackEggStorePostErrors.rack_egg_id"
        )
          template(v-slot:selection="{ item }")
            span {{ item.incubator_rack.code }}
          template(v-slot:item="{ item }")
            span(
              :class="updatedRackId(item.created_at, item.updated_at) + '--text'"
            ) {{ item.incubator_rack.code }}
        v-text-field(
          v-else
          label="Incubator Rack"
          disabled
          :value="defaultData.rack_code.code"
          dense
          hide-details="auto"
        )
      v-col(cols="12" lg="6" md="6")
        v-text-field(
          dense
          hide-details="auto"
          label="Good"
          type="number"
          v-model.number="good"
          :error-messages="candledRackEggStorePostErrors.good"
          clearable
        )
      v-col(cols="12" lg="6" md="6")
        v-text-field(
          dense
          hide-details="auto"
          label="Bad"
          type="number"
          v-model.number="bad"
          clearable
          :error-messages="candledRackEggStorePostErrors.bad"
        )
      v-col(cols="12" lg="6" md="6")
        v-text-field(
          dense
          hide-details="auto"
          label="Infertile"
          type="number"
          v-model.number="infertile"
          :error-messages="candledRackEggStorePostErrors.infertile"
          clearable
        )
      v-col(cols="12" lg="6" md="6")
        v-text-field(
          dense
          hide-details="auto"
          label="Dead Embryo"
          type="number"
          v-model.number="deadEmbryo"
          :error-messages="candledRackEggStorePostErrors.dead_embryo"
          clearable
        )
      v-col(cols="12" lg="6" md="6")
        v-text-field(
          dense
          hide-details="auto"
          label="Harvested"
          type="number"
          v-model.number="harvested"
          :error-messages="candledRackEggStorePostErrors.harvested"
          clearable
        )
      v-col(cols="12")
        v-text-field(
          dense
          hide-details="auto"
          label="Unaccounted"
          type="number"
          v-model.number="unaccounted"
          :error-messages="candledRackEggStorePostErrors.unaccounted"
          clearable
        )
      v-col(cols="12" lg="6" md="6")
        v-text-field(
          dense
          hide-details="auto"
          label="Date"
          type="date"
          v-model="date"
          clearable
          :error-messages="candledRackEggStorePostErrors.date"
        )
      v-col(cols="12" v-if="!editMode")
        v-btn(
          color="green"
          block
          dark
          dense
          :loading="candledRackEggStorePosting"
          @click="store"
        )
          span save
      v-col(cols="12" v-else)
        v-row
          v-col(cols="6")
            v-btn(
              color="yellow darken-3"
              dense
              block
              dark
              :loading="candledRackEggStorePosting"
              @click="update"
            ) Update
          v-col(cols="6")
            v-btn(
              color="red"
              dense
              block
              dark
              @click="editMode = false"
            ) Cancel
</template>
<script>
import rackEggRepository from '@/repositories/rack-egg.repository'
import candledRackEggRepository from '@/repositories/candled-rack-egg.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [incubatorRackIdsVars, incubatorRackIdsVarNames] = requestVars({ identifier: 'incubator-rack-ids' })
const [candledRackEggStoreVars, candledRackEggStoreVarNames] = requestVars({ identifier: 'candled-rack-egg-store', request: 'post', hasData: false })

const incubatorRackIdsHandler = new VueRequestHandler(null, incubatorRackIdsVarNames)
const candledRackEggStoreHandler = new VueRequestHandler(null, candledRackEggStoreVarNames)

const inputVars = () => ({
  id: null,
  rackEggId: null,
  good: null,
  bad: null,
  deadEmbryo: null,
  infertile: null,
  harvested: null,
  unaccounted: null,
  date: Window.getCurrentDate(),
})

export default {
  name: 'CreateCandledRackEggs',
  props: {
    defaultData: Object,
  },
  data () {
    return {
      ...inputVars(),
      ...incubatorRackIdsVars,
      ...candledRackEggStoreVars,
      searchIncubatorRackIds: null,
      incubatorCode: null,
    }
  },
  computed: {
    editMode: {
      get () {
        const { date, ...data } = this.defaultData
        return !this.$objectEmpty(data)
      },
      set (val) {
        if (val) return
        const { date } = this
        this.$emit('update:default-data', { date })
      },
    },
    accessLevel () {
      return this.$store.state.auth.accessLevel
    },
    filteredIncubatorRackIdsList () {
      return this.incubatorRackIdsList.filter(item => {
        if (!this.incubatorCode) return true
        const [incubatorCode] = item.incubator_rack.code.split('-')
        return incubatorCode === this.incubatorCode
      })
    },
    incubatorList () {
      const reduceIncubatorRack = (cache = {}) => (result, item) => {
        const [incubatorCode] = item?.incubator_rack?.code.split('-')
        if (cache[incubatorCode]) return result
        cache[incubatorCode] = true
        result.push(incubatorCode)
        return result
      }
      return this.incubatorRackIdsList.reduce(reduceIncubatorRack(), [])
    },
  },
  created () {
    this.getIncubatorIds()
  },
  watch: {
    searchIncubatorRackIds () {
      this.getIncubatorIds()
    },
    defaultData () {
      const input = inputVars()
      this.$populateInputData({ input })
    },
  },
  methods: {
    getIncubatorIds () {
      const handler = incubatorRackIdsHandler
      const repository = rackEggRepository.incubatorRackIds
      const params = { search: this.searchIncubatorRackIds }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    async store () {
      const confirm = await this.$confirm('are you sure you want to save this data?')
      if (!confirm) return
      const handler = candledRackEggStoreHandler
      const repository = candledRackEggRepository.store
      const { id, ...data } = this.$getInputData(inputVars())
      const { date, ...defaults } = inputVars()
      handler.setVue(this)
      handler.execute(repository, [data], this.$resetInputData(defaults))
    },
    async update () {
      const confirm = await this.$confirm('are you sure you want to update this data?')
      if (!confirm) return
      const handler = candledRackEggStoreHandler
      const repository = candledRackEggRepository.update
      const { id, ...data } = this.$getInputData(inputVars())
      handler.setVue(this)
      handler.execute(repository, [id, data], () => { this.editMode = false })
    },
    updatedRackId (createdAt, updatedAt) {
      if (createdAt === updatedAt) return 'success'
      if (updatedAt.dateFormat({}, 'sv-SE') === Window.getCurrentDate()) return 'primary'
      return 'blue-grey'
    },
  },  
}
</script>